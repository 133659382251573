@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

.mv {
  background: url(../images/index/mv.png) center top no-repeat;
  background-size: cover;
  width: 100%;
  height: 967px;
}

@media screen and (max-width: 769px) {
  .mv {
    background: url(../images/index/mv_sp.png) center top no-repeat;
    background-size: cover;
    height: 733px;
  }
}

.mv__inner {
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.mv__inner__title {
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 647px;
  position: absolute;
  top: 40px;
  left: 0;
}

@media screen and (max-width: 769px) {
  .mv__inner__title {
    width: 60px;
    height: 500px;
    padding: 0;
    top: auto;
    bottom: 180px;
  }
}

@media screen and (max-width: 769px) {
  .mv__inner__title.fadein {
    opacity: 1;
    transform: translateY(0);
  }
}

.mv__inner__title__txt {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #fff;
  font-size: 26px;
  font-size: 2.6rem;
  font-family: "Noto Serif JP",serif;
  font-weight: 400;
  letter-spacing: .5em;
  display: block;
  line-height: 1;
  white-space: nowrap;

  span {
    color: #bf000a;
  }
}

@media screen and (max-width: 769px) {
  .mv__inner__title__txt {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 769px) {
  .mv__inner__title__txt.fadein {
    opacity: 1;
    transform: translateY(0);
  }
}

.mv__inner__txt {
  max-width: 340px;
  position: absolute;
  bottom: 250px;
  right: 90px;
  color: #fff;
  font-size: 21px;
  font-size: 2.1rem;
  font-family: "Noto Serif JP",serif;
  font-weight: 400;
}

@media screen and (max-width: 769px) {
  .mv__inner__txt {
    max-width: 60%;
    bottom: 180px;
    font-size: 14px;
    font-size: 1.4rem;
    right: 20px;
  }
}

@media screen and (max-width: 769px) {
  .mv__inner__txt.fadein {
    opacity: 1;
    transform: translateY(0);
  }
}

.about {
  background: #fff;
  max-width: 845px;
  margin: -200px auto 0;
  padding: 50px 0;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 769px) {
  .about {
    width: 90%;
    margin: -150px auto 0;
  }
}

.about__title:after {
  background: #bf000a;
}

.about__txt {
  width: 645px;
  margin: 0 auto;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Noto Serif JP",serif;
  line-height: 2;
}

@media screen and (max-width: 769px) {
  .about__txt {
    width: 85%;
    box-sizing: border-box;
  }
}

.about__btnarea {
  width: 246px;
  margin: 40px auto 0;

  .btn:hover {
    background: #000;
    color: #fff;
  }
}

@media screen and (max-width: 769px) {
  .about.fadein {
    opacity: 1;
  }
}

.news {
  background: #000;
  margin-top: -160px;
  padding: 240px 0 80px;
}

@media screen and (max-width: 769px) {
  .news {
    margin-top: -305px;
    padding: 355px 0 70px;
    box-sizing: border-box;
  }
}

.news__title {
  color: #fff;

  &:after {
    background: #fff;
  }
}

.news__list {
  max-width: 1024px;
  margin: 0 auto;

  li a {
    display: flex;
    max-width: 845px;
    margin: 0 auto;
    padding: 30px 0;
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem;
    border-bottom: 1px solid #fff;

    span {
      background: #bf000a;
      width: 80px;
      height: 22px;
      text-align: center;
      color: #fff;
      line-height: 22px;
      border-radius: 6px;
      margin-left: 15px;
    }

    p {
      max-width: 640px;
      font-family: "Noto Serif JP",serif;
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 980px) {
  .news__list {
    padding: 0 20px;
  }
}

@media screen and (max-width: 769px) {
  .news__list li a {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 769px) {
  .news__list li a p {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.news__btnarea {
  width: 246px;
  margin: 40px auto 0;

  .btn {
    border-color: #fff;
    color: #fff;

    &:hover {
      background: #fff;
      color: #000;
    }
  }
}

.linkarea {
  display: flex;
  flex-wrap: wrap;
}

.linkarea__inner {
  box-sizing: border-box;

  a {
    display: block;
    position: relative;
    transition: all .3s ease;

    p {
      background: #000;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100px;
      color: #fff;
      text-align: center;
      font-size: 21px;
      font-size: 2.1rem;
      font-family: "Noto Serif JP",serif;
      line-height: 100px;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 30px;
        margin-top: -5px;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: #fff;
        transform: rotateZ(45deg);
        border-color: #bf000a;
        right: 15px;
      }
    }

    &:hover {
      opacity: .8;
    }
  }

  &.aboutbook {
    width: 100%;

    a {
      background: url(../images/index/bg_01.png) center center no-repeat;
      background-size: cover;
      height: 650px;
      flex-grow: 1;
      border-bottom: 1px solid #fff;
    }

    &.en{
      a{
        background: url(../images/index/en_bg_01.png) center center no-repeat;
      }
    }
  }

  &.flow {
    width: 50%;

    a {
      background: url(../images/index/bg_02.png) center center no-repeat;
      background-size: cover;
      height: 512px;
      border-bottom: 1px solid #fff;
    }

    &.en{
      a{
        background: url(../images/index/en_bg_02.png) center center no-repeat;

      }
    }
  }

  &.problem {
    width: 50%;

    a {
      background: url(../images/index/bg_03.png) center center no-repeat;
      background-size: cover;
      height: 512px;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    &.en{
      a{
        background: url(../images/index/en_bg_03.png) center center no-repeat;

      }
    }
  }
}

@media screen and (max-width: 769px) {
  .linkarea__inner a p {
    height: 35px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 35px;
  }
}

@media screen and (max-width: 320px) {
  .linkarea__inner a p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 769px) {
  .linkarea__inner.aboutbook a {
    height: 273px;
  }
}

@media screen and (max-width: 769px) {
  .linkarea__inner.flow a {
    height: 223px;
  }
}

@media screen and (max-width: 769px) {
  .linkarea__inner.problem a {
    height: 223px;
  }
}

.official {
  background: url(../images/index/bg_04.png) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 747px;
}

.official.advanced {
  background: url(../images/index/advanced.png) 80% 40% no-repeat;
  background-size: 30%;
  background-color: #000;
  width: 100%;
  height: 747px;

  @media screen and (max-width: 769px) {
    height: auto;
  }
}
@media screen and (max-width: 769px) {
  .official {
    background: 0 0;
    background-color: #000;
    height: auto;
    padding: 70px 0;
    box-sizing: border-box;

    &.advanced {
      background-image: none;
    }
  }
}

.official__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  max-width: 980px;
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 980px) {
  .official__inner {
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.official__inner__contents {
  max-width: 360px;
  color: #fff;
}

@media screen and (max-width: 769px) {
  .official__inner__contents {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}

.official__inner__contents__title {
  color: #fff;

  &:after {
    background: #fff;
  }
}

.official__inner__contents__txt {
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Noto Serif JP",serif;
  line-height: 2;
}

.official__inner__contents__btnarea {
  width: 246px;
  margin: 40px auto 0;

  .btn {
    background: #bf000a;
    color: #fff;

    &:hover {
      background: #fff;
      color: #000;
    }
  }
}

@media screen and (max-width: 320px) {
  .official__inner__contents__btnarea {
    width: 85%;
  }
}



#english {
  .mv__inner__title{
    width: 55px;
    .mv__inner__title__txt{
      font-size: 1.1rem;
      @media screen and (max-width: 769px) {
        font-size: .7rem;
      }
    }
  }
  .news{
    .news__list li a span{
      width: 105px;
    }
  }
}


/*# sourceMappingURL=sourcemaps/index.css.map */
